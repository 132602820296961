const theme = {
    colors: {
        primary: '#4A5869',
        primaryLight: '#F5A46C',
        primaryDark: '#9FC2BB',
        bg: '#fff',
        text: '#021212',
        textInvert: '#fff',
        greyLight: '#B4B9C1',
        greyMedium: '#414141',
        greyDark: '#2F2F2F',
    },
    fontFamily: {
        body: `'Open Sans', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
        heading: `'Bree Serif', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    },
    maxWidth: '1800px',
    maxWidthText: '1000px',
    breakpoint: {
        xs: '400px',
        s: '600px',
        m: '900px',
        l: '1200px',
    },
};

export default theme;